import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import PropTypes from 'prop-types';
import Container from 'components/shared/Container';
import LazyImage from 'components/shared/LazyImage';
import { HeadingThree, TextLarge } from 'components/shared/Typography';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';

const Wrapper = styled.section`
  position: relative;
  margin: 90px 0 0 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 60px 0;
`;

const Pictogram = styled.div`
  margin: 0 0 40px 0;
  display: flex;
  align-items: center;
`;
const PictogramIcon = styled.div`
  width: 60px;
  height: 60px;
`;
const PictogramContent = styled.div`
  width: calc(100% - 100px);
  margin: 0 0 0 40px;
  padding: 0 80px 0 0;
`;

const Cta = styled.div`
  margin: 25px 0 0 0;
  display: flex;
  justify-content: center;
`;

const Background = styled(LazyImage)`
  width: 100%;
  position: relative;
`;

const ButtonStyled = styled(Link)``;

const Services = ({ background, pictograms, buttonText, buttonUrl }) => {
  return (
    <Wrapper>
      <Container>
        <Header>
          <HeadingThree>Pozostałe usługi</HeadingThree>
        </Header>
        <Row>
          {pictograms &&
            pictograms.map((item, index) => (
              <Col lg={4}>
                <Pictogram key={index}>
                  <PictogramIcon>
                    <Icon size={60} src={item.icon} />
                  </PictogramIcon>
                  <PictogramContent>
                    <TextLarge weight="600">{item.text}</TextLarge>
                  </PictogramContent>
                </Pictogram>
              </Col>
            ))}
        </Row>
        {buttonText && (
          <Cta>
            <ButtonStyled to={buttonUrl}>
              <Button primary>{buttonText}</Button>
            </ButtonStyled>
          </Cta>
        )}
      </Container>
      <Background cover src={background} />
    </Wrapper>
  );
};

Services.propTypes = {
  pictograms: PropTypes.oneOfType([PropTypes.string]).isRequired,
  background: PropTypes.oneOfType([PropTypes.string]),
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
};

Services.defaultProps = {
  background: '',
  buttonText: 'Pokaż ofertę',
  buttonUrl: '/',
};

export default Services;
