import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from 'components/shared/Container';
import { HeadingOne, Text } from 'components/shared/Typography';
import LazyImage from 'components/shared/LazyImage';

const Wrapper = styled.section`
  position: relative;
  padding: 250px 0;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, #00102e, #00102e38);
    z-index: 1;
  }

  @media screen and (max-width: 992px) {
    padding: 150px 0;
  }
`;

const WrapperInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 500px;
  z-index: 2;
`;

const Subtitle = styled(Text)`
  margin: 0 0 15px 0;
  padding: 0;
`;
const Title = styled(HeadingOne)`
  margin: 0;
  padding: 0;
  line-height: 1.4;
`;
const Content = styled(Text)`
  margin: 15px 0 0 0;
  padding: 0;
  line-height: 1.75;
`;

const Background = styled(LazyImage)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const Header = ({ photo, subtitle, title, text }) => {
  return (
    <>
      <Wrapper role="banner">
        <Background src={photo} cover alt="" />
        <Container fullHeight>
          <WrapperInner>
            {subtitle && <Subtitle color="lightOpacity">{subtitle}</Subtitle>}
            <Title
              color="white"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            <Content color="lightOpacity">{text}</Content>
          </WrapperInner>
        </Container>
      </Wrapper>
    </>
  );
};

Header.defaultProps = {
  subtitle: null,
};

Header.propTypes = {
  photo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Header;
