import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LazyImage from 'components/shared/LazyImage';
import Container from 'components/shared/Container';
import { Text } from 'components/shared/Typography';

const Wrapper = styled.section`
  position: relative;
  padding: 0;
  margin: -40px 0 0 0;
  z-index: 9;
`;

const WrapperInner = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 50px;
  background-color: #fff;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    padding: 10px 20px;
  }
`;

const Content = styled.div`
  width: 110px;
  margin: 0 50px 0 0;

  @media screen and (max-width: 992px) {
    margin: 15px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  width: calc(100% - 160px);

  @media screen and (max-width: 992px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

const ListItem = styled.li`
  width: calc(100% / 6);

  @media screen and (max-width: 992px) {
    margin: 5px 0;
    width: 50%;
  }
`;

const ListImage = styled(LazyImage)`
  max-height: 60px;
  max-width: 100%;
`;

const Partners = ({ heading, list }) => {
  return (
    <Wrapper>
      <Container>
        <WrapperInner>
          <Content>
            <Text weight="700">{heading}</Text>
          </Content>
          <List>
            {list &&
              list.map((item, index) => (
                <ListItem key={index}>
                  <ListImage src={item.src} alt="Image" />
                </ListItem>
              ))}
          </List>
        </WrapperInner>
      </Container>
    </Wrapper>
  );
};

Partners.propTypes = {
  heading: PropTypes.string.isRequired,
  list: PropTypes.oneOfType([PropTypes.string]).isRequired,
};

export default Partners;
