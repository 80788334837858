import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';

import Container from 'components/shared/Container';
import { HeadingThree, Text } from 'components/shared/Typography';
import TestimonialsTile from 'components/Testimonials/Tile';

SwiperCore.use([Pagination, Navigation]);

const Wrapper = styled.section`
  & .swiper-container {
    padding: 0 0 65px 0;
  }
  & .swiper-slide {
    height: initial !important;
  }
  & .swiper-pagination {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 5px;
  }
  & .swiper-button-next,
  & .swiper-button-prev {
    position: absolute;
    top: unset;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: ${({ theme }) => theme.colors.greenPrimary};
    transition: background-color 300ms ease-in-out;
    z-index: 99;
    &:after {
      font-family: swiper-icons;
      text-transform: none !important;
      letter-spacing: 0;
      text-transform: none;
      font-variant: initial;
      line-height: 1;
      font-size: 20px;
      color: #fff;
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.greenVariant};
    }
  }
  & .swiper-button-next {
    right: calc(50% - 200px);
    &:after {
      content: 'next';
    }
    @media screen and (max-width: 992px) {
      right: 0 !important;
    }
  }
  & .swiper-button-prev {
    left: calc(50% - 200px);
    &:after {
      content: 'prev';
    }
    @media screen and (max-width: 992px) {
      left: 0 !important;
    }
  }
  & .swiper-pagination-bullet {
    margin: 0 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 30px;
    height: 30px;
    opacity: 1;
    cursor: pointer;
    &:before,
    &:after {
      position: absolute;
      content: '';
      display: block;
      transition: all 300ms ease-in-out;
      opacity: 1;
    }
    &:before {
      border-radius: 30px;
      width: 30px;
      height: 30px;
      border: 1px solid #e0e0e0;
    }
    &:after {
      border-radius: 6px;
      width: 6px;
      height: 6px;
      background-color: #e0e0e0;
    }
    &.swiper-pagination-bullet-active {
      &:after {
        background-color: ${({ theme }) => theme.colors.greenPrimary};
      }
    }
  }
`;

const Content = styled.div`
  margin: 90px 0 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Subheading = styled(Text)`
  margin: 0 0 5px 0;
  text-transform: uppercase;
  font-weight: 600;
`;
const Heading = styled(HeadingThree)``;

const Testimonials = ({ subheading, heading, tiles }) => {
  return (
    <Wrapper>
      <Container>
        <Content>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
        </Content>
        <Swiper
          slidesPerView={1}
          spaceBetween={25}
          pagination={{ clickable: true }}
          loop
          navigation
          breakpoints={{
            991: {
              slidesPerView: 2,
            },
          }}
        >
          {tiles &&
            tiles.map((item, index) => (
              <SwiperSlide key={index}>
                <TestimonialsTile content={item.content} author={item.author} />
              </SwiperSlide>
            ))}
        </Swiper>
      </Container>
    </Wrapper>
  );
};

Testimonials.propTypes = {
  tiles: PropTypes.oneOfType([PropTypes.string]).isRequired,
  subheading: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};

export default Testimonials;
