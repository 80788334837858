import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import PropTypes from 'prop-types';

import Container from 'components/shared/Container';
import LazyImage from 'components/shared/LazyImage';
import Tile from 'components/WhyUs/Tile';
import Services from 'components/WhyUs/Services';
import { HeadingThree, Text, TextLarge } from 'components/shared/Typography';

const Wrapper = styled.section`
  position: relative;
`;

const Tiles = styled.div`
  display: flex;
  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 60px 0;

  @media screen and (max-width: 992px) {
    padding: 60px 0 0 0;
  }
`;

const Subheading = styled(Text)`
  margin: 0 0 15px 0;
  text-transform: uppercase;
  font-weight: 600;
`;
const Heading = styled(HeadingThree)`
  max-width: 360px;
  margin: 0;
`;
const ContentInner = styled(TextLarge)`
  margin: 25px 0 0 0;
  max-width: 550px;
`;

const WhyUs = ({
  subheading,
  heading,
  text,
  tiles,
  pictograms,
  photo,
  background,
  buttonText,
  buttonUrl,
}) => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col lg={6}>
            <Content>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
              {text && <ContentInner>{text}</ContentInner>}
            </Content>
          </Col>
          <Col lg={6}>
            <LazyImage cover src={photo} />
          </Col>
        </Row>
      </Container>
      <Tiles>
        {tiles &&
          tiles.map(item => (
            <Tile image={item.image} title={item.title} text={item.content} />
          ))}
      </Tiles>
      <Services
        pictograms={pictograms}
        background={background}
        buttonText={buttonText}
        buttonUrl={buttonUrl}
      />
    </Wrapper>
  );
};

WhyUs.propTypes = {
  tiles: PropTypes.oneOfType([PropTypes.string]).isRequired,
  pictograms: PropTypes.oneOfType([PropTypes.string]).isRequired,
  background: PropTypes.oneOfType([PropTypes.string]).isRequired,
  photo: PropTypes.oneOfType([PropTypes.string]).isRequired,
  subheading: PropTypes.string,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
};

WhyUs.defaultProps = {
  subheading: '',
  text: '',
  buttonText: 'Pokaż ofertę',
  buttonUrl: '/',
};

export default WhyUs;
