import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import SEO from 'components/shared/SEO';
import HeaderMain from 'components/Header/HeaderMain';
import Partners from 'components/Partners/Partners';
import WhyUs from 'components/WhyUs/WhyUs';
import Testimonials from 'components/Testimonials/Carousel';

const Wrapper = styled.div`
  margin: 0 0 200px 0;

  @media screen and (max-width: 992px) {
    margin: 0 0 105px 0;
  }
`;

const IndexPage = ({ data }) => {
  const PAGE = data.wpPage.PageIndex;

  const partnersList = PAGE.homePartnersList.map(
    ({ homePartnersListImage }) => ({
      src: homePartnersListImage.localFile.childImageSharp.gatsbyImageData,
    })
  );

  const whyusTiles = PAGE.homeAboutTabs.map(
    ({ homeAboutTabsImage, homeAboutTabsText, homeAboutTabsHeading }) => ({
      image: homeAboutTabsImage.localFile.childImageSharp.gatsbyImageData,
      title: homeAboutTabsHeading,
      content: homeAboutTabsText,
    })
  );

  const whyusPictograms = PAGE.homeServicesPictograms.map(
    ({ homeServicesPictogramsIcon, homeServicesPictogramsContent }) => ({
      icon: homeServicesPictogramsIcon.mediaItemUrl,
      text: homeServicesPictogramsContent,
    })
  );

  const testimonialsTiles = PAGE.homeTestimonialsList.map(
    ({ homeTestimonialsListAuthor, homeTestimonialsListContent }) => ({
      content: homeTestimonialsListContent,
      author: homeTestimonialsListAuthor,
    })
  );

  return (
    <>
      <SEO title="Strona główna" />
      <Wrapper>
        <HeaderMain
          photo={PAGE.homeBannerImage.localFile.childImageSharp.gatsbyImageData}
          subtitle={PAGE.homeBannerSubheading}
          title={PAGE.homeBannerHeading}
          text={PAGE.homeBannerText}
        />
        <Partners heading={PAGE.homePartnersHeading} list={partnersList} />
        <WhyUs
          subheading={PAGE.homeAboutSubheading}
          heading={PAGE.homeAboutHeading}
          text={PAGE.homeAboutText}
          photo={PAGE.homeAboutImage.localFile.childImageSharp.gatsbyImageData}
          tiles={whyusTiles}
          pictograms={whyusPictograms}
          background={
            PAGE.homeServicesBackground.localFile.childImageSharp
              .gatsbyImageData
          }
          buttonText={PAGE.homeServicesButtonText}
          buttonUrl={PAGE.homeServicesButtonUrl}
        />
        <Testimonials
          subheading={PAGE.homeTestimonialsSubheading}
          heading={PAGE.homeTestimonialsHeading}
          tiles={testimonialsTiles}
        />
      </Wrapper>
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryPageIndex {
    wpPage(slug: { eq: "strona-glowna" }) {
      PageIndex {
        homeAboutHeading
        homeAboutSubheading
        homeAboutText
        homeAboutTabs {
          homeAboutTabsText
          homeAboutTabsImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: BLURRED
                  formats: [WEBP]
                )
              }
            }
          }
          homeAboutTabsHeading
        }
        homeAboutImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [WEBP]
              )
            }
          }
        }
        homeBannerHeading
        homeBannerSubheading
        homeBannerText
        homePartnersHeading
        homePartnersList {
          homePartnersListImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: BLURRED
                  formats: [WEBP]
                )
              }
            }
          }
        }
        homeBannerImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [WEBP]
              )
            }
          }
        }
        homePartnersHeading
        homeServicesButtonText
        homeServicesButtonUrl
        homeServicesHeading
        homeServicesPictograms {
          homeServicesPictogramsIcon {
            mediaItemUrl
          }
          homeServicesPictogramsContent
        }
        homeServicesBackground {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [WEBP]
              )
            }
          }
        }
        homeTestimonialsHeading
        homeTestimonialsSubheading
        homeTestimonialsList {
          homeTestimonialsListAuthor
          homeTestimonialsListContent
        }
      }
    }
  }
`;

export default IndexPage;
